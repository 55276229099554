@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
  overflow-x: hidden;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Ginchiest';
  src: local('Ginchiest'), url('./fonts/Ginchiest.woff') format('truetype');
  font-weight: normal;
}

.ginchiest {
  font-family: 'Ginchiest';
}

.MuiTypography-root {
  color: white;
}

.MuiButton-label {
  color: white;
}

.MuiButton-containedPrimary {
  background-color: white !important;
}

.MuiButton-label {
  color: black !important;
  font-family: 'Poppins', sans-serif !important;
  text-transform: none !important;
}

.MuiButton-iconSizeMedium {
  display: none !important;
}

.MuiList-root {
  background-color: white !important;
}
.MuiListItem-root {
  box-shadow: inset 0 1px 0 0 rgb(0 0 0 / 10%) !important;
}

@keyframes ping-slow {
  75%,
  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
.animate-ping-slow {
  animation: ping-slow 5s cubic-bezier(0, 0, 0.2, 1) infinite;
}
